<template>
  <div :class="{container:true,notScroll:isScroll}">
    <!-- 顶部 -->
    <MoTitle :showLeft="false" title="关于我们" @moTitltFn="navPanel"></MoTitle>
    <div class="aboutContainer">
        <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 24px;margin-bottom:20px">关于我们</h1>
          <button class="showBtn" @click="$router.push('/moShow')">联系我们</button>
      </div>
      <!-- 公司介绍 -->
      <div class="contentBgColor center">
          <h2 class="h2Desc">公司介绍</h2>
          <span class="spanDesc"></span>
          <div class="intraduce">
            <p style="line-height: 24px;font-size: 14px;color: #222222;">&emsp;&emsp;北京易普行科技有限公司，成立于2010年,是国内专注于为高校提供人力资源管理与服务数字化解决方案的软件企业。</p>
            <p style="line-height: 24px;font-size: 14px;color: #222222;">
              &emsp;&emsp;立足高校人力资源业务的本质和数字化转型的趋势，公司秉持专业精神，将高校人力资源最佳业务实践融入到平台原型,建设了涵盖招聘与人才引进、基础人事、师资培养、全面薪酬、岗位评聘、考核测评、组干与党务、统战管理、离退休服务的高校人力资源管理与服务平台,为高校用户提供高度匹配的人力资源数字化解决方案。目前，国内高校用户已经超过400家，备受客户信赖，已成为高校人力资源管理信息化服务提供商的典范。
            </p>
          </div>
          <div class="intraLeft" @click="play = true">
            <img src="@/assets/about/playMask.png" alt="" class="palyFengm" style="">
            <img src="@/assets/about/play.png" alt="" class="intraPlayBtn" >
          </div>
      </div>

      <div class="moWishContent">
        <div class="moWishContentDsec">
          <img class="qiywhItemImg" src="@/assets/about/yuanj.png" alt="">
          <span class="qiywhItemTitlt">愿景</span>
          <span class="qiywhItemText">
            高校人力资源数字化领航者
          </span>
        </div>
        <div class="moWishContentDsec">
          <img class="qiywhItemImg" src="@/assets/about/shiming.png" alt="">
          <span class="qiywhItemTitlt">使命</span>
          <span class="qiywhItemText" style="text-align:center">
            我们坚信人力资源管理在高校变革与发展过程中的专业价值，易普行的使命，就是让这种价值得到最大程度的发挥和体现
          </span>
        </div>
        <div class="moWishContentDsec">
          <img class="qiywhItemImg" src="@/assets/about/jiazhig.png" alt="">
          <span class="qiywhItemTitlt">价值观</span>
          <span class="qiywhItemText">
            <div class="qiywhItemText">专业主义、团队精神、用户立场</div>
            <div class="qiywhItemText">自我驱动、学习精进、可以信赖</div>
          </span>
        </div>
      </div>

      <div class="moProcessContent center">
        <h2 class="h2Desc">发展历程</h2>
        <span class="spanDesc"></span>
        <div style="font-size: 15px;font-weight: 400;color: #222222;line-height: 24px;margin-top:20px">
          易普行始终聚焦于高校人力资源信息化/数字化建设
        </div>
        <div class="processContainer">
          <div class="processStepContent">
            <img src="@/assets/Mobile/about/process.png" alt="" style="width:100%">
            <div v-for="(item,index) in stepList" :key="index" :class="{processStepItem:true,processStepActive:stepAcive === index}" :style="item.style" @click="stepAcive = index">
              <div class="processQunOne"></div>
              <div class="processQunTwo"></div>
              <span :class="{processStepText:true,processStepTextActive:stepAcive === index}">{{item.title}}</span>
            </div>
            <span class="stepDescPosition" style="top:50px;left:26%">定制化</span>
            <span class="stepDescPosition" style="top:50px;right:27%">产品化</span>
            <span class="stepDescPosition" style="bottom:8%;right:13%">项目化</span>
            <span class="stepDescPosition" style="bottom:8%;right:45%">生态化</span>
          </div>

          <div v-for="(item,index) in stepList" :key="index">
            <div v-if="stepAcive === index" class="processItem">
              <div class="processItemTitle">{{item.textTitle}}</div>
              <div class="processText">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="moZzzsContainer center">
        <h2 class="h2Desc">资质证书</h2>
        <span class="spanDesc" style="margin-bottom: 50px;"></span>
        <div class="zzzsListCon">
          <div class="zzzsList" ref="zzzsData" >
            <img v-for="(item,index) in zzzsList" :key="index" :src="item.img" alt="" style="width:1660px;"  @mouseenter="handZzzs(index)" @mouseleave="handZzzs(null)">
          </div>
        </div>
      </div>

      <div class="morongyzmContainer center">
        <h2 class="h2Desc">荣誉证明</h2>
        <span class="spanDesc"></span>
        <div class="ryzmListCOntent">
          <div class="ryzmList" ref="thankData">
            <div v-for="(item,index) in thanksList" :key="index" class="ryzmItem" @mouseenter="handSchool(index)" @mouseleave="handSchool(null)">
              <img :src="item.src" alt="" style="width:180px">
            </div>
          </div>
        </div>
      </div>

      <div class="moJoinusContainer center">
        <h2 class="h2Desc">加入我们</h2>
        <span class="spanDesc"></span>
        <div style="font-size: 15px;font-weight: 400;color: #222222;line-height: 24px;margin-top:20px;margin-bottom:30px">
          诚聘英才，易普行期待您的加入
        </div>
        <div class="moJoinCompany">
          <el-carousel height="200px" class="joinUsTop" arrow="never">
            <el-carousel-item v-for="(item,index) in companyList" :key="index" >
              <img :src="item.img" alt="" style="width:100%;height:100%">
            </el-carousel-item>
          </el-carousel>
          <div class="joinUsContent">
            <img src="@/assets/about/hi.png" alt="" style="width:50px">
            <div style="font-size: 16px;font-weight: 400;color: #222222;line-height: 26px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);margin-top:10px">
              这里拥有广阔发展平台和成长机遇，<br/>与志同道合的伙伴一起，成就公司，成就自己！
            </div>
            <div style="width:100%;text-align:center">
              <el-button class="joinUsBtn" @click="dialogShow=true">加入我们</el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="moPersonContainer center">
        <h2 class="h2Desc">员工风采</h2>
        <span class="spanDesc"></span>
        <div class="personContent">
          <el-carousel height="100px" arrow="never">
            <el-carousel-item v-for="(item,index) in ygList" :key="index" >
              <img :src="item.img" alt="" style="width:100%;object-fit: cover;">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="fullPageVideo" v-if="play">
        <div class="videoWrap">
          <span class="el-icon-error closePlay" @click="play = false"></span>
          <video width="100%" autoplay loop controls preload="auto" webkit-playsinline="true" playsinline="true">
            <source src="@/assets/about/vidio.mp4" type="">
          </video>
        </div>
      </div>

      <el-dialog
        title="简历投递"
        :visible.sync="dialogShow"
        width="90%" top="15vh"
        :before-close="()=>{dialogShow=false}">
        <div class="jianliCOntent">
          <div class="jianliTop">
            <img src="@/assets/jianli.png" alt="" style="width:100%;height:100px">
            <div class="jianliTopText">诚聘英才，易普行期待您的加入</div>
          </div>
          <div style="margin:20px 0;">
            <div style="background:#fff2e1;padding:6px 16px;font-size: 16px;color:#666;">
              请您将简历通过邮件发送给我们，我们会认真评估您的简历，若有需要会与您取得联系。
            </div>
            <div style="font-size: 16px;color:#222;margin:20px 0;">
              <span style="display:inline-block;width:80px;text-align-last: justify;text-align: justify;">工作地点</span>：
              <span>北京、上海、武汉、广州</span>
            </div>
            <div style="font-size: 16px;color:#222;">
              <span style="display:inline-block;width:80px;text-align-last: justify;text-align: justify;">邮箱</span>：
              <span>pmh@epxing.com</span>
            </div>
          </div>
        </div>
      </el-dialog>
      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moAbout',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      play: false,
      stepAcive: 0,
      stepList: [
        { title: '2010', style: { left: '20px', top: '45px' }, content: '公司成立；开始从事高校人事信息化并始终保持专注；纯代码定制化，积累高校人力资源领域的业务、技术和项目经验。', textTitle: '2010年' },
        { title: '2013', style: { left: '43%', top: '45px' }, content: '将高校人力资源业务做法抽象成标准功能，推出高校人力资源管理系统产品，迭代了V5/V6/V7三个版本；不断提升产品与高校业务的匹配度，聚焦于交付“功能”。', textTitle: '2013年' },
        { title: '2018', style: { left: '82%', top: '45px' }, content: '聚焦于“最佳业务实践”，更注重高校个性化业务特点和需求；提高系统的可配置性、可扩展性等低代码能力，推出高校人力资源管理平台V8.0，走向项目化实施。', textTitle: '2018年' },
        { title: '2022', style: { right: '25%', bottom: '-15px' }, content: '正式推出高校低代码平台aPaaS/V9；聚焦于业务目标的实现，注重效率、专业能力、创新能力等用户多维价值，转型为解决方案模式。', textTitle: '2022年' },
        { title: '未来', style: { left: '25%', bottom: '-15px' }, content: '将继续深耕高校业务，强化aPaaS能力，围绕“People+”，在应用、场景、技术、服务等创新方面，与高校用户、行业伙伴、领域专家等进行共创共享，希望能走向生态模式。', textTitle: '未来' }
      ],
      zzzsTime: null,
      zzzsList: [
        { img: require('@/assets/about/zzzs.png') },
        { img: require('@/assets/about/zzzs.png') },
        { img: require('@/assets/about/zzzs.png') },
        { img: require('@/assets/about/zzzs.png') }
      ],
      thanksList: [
        { src: require('@/assets/about/thank/one.png') },
        { src: require('@/assets/about/thank/tow.png') },
        { src: require('@/assets/about/thank/three.png') },
        { src: require('@/assets/about/thank/four.png') },
        { src: require('@/assets/about/thank/five.png') },
        { src: require('@/assets/about/thank/six.png') },
        { src: require('@/assets/about/thank/seven.png') },
        { src: require('@/assets/about/thank/eight.png') },
        { src: require('@/assets/about/thank/nine.png') },
        { src: require('@/assets/about/thank/ten.png') },
        { src: require('@/assets/about/thank/eleven.png') },
        { src: require('@/assets/about/thank/twelve.png') },
        { src: require('@/assets/about/thank/one.png') },
        { src: require('@/assets/about/thank/tow.png') },
        { src: require('@/assets/about/thank/three.png') },
        { src: require('@/assets/about/thank/four.png') },
        { src: require('@/assets/about/thank/five.png') },
        { src: require('@/assets/about/thank/six.png') },
        { src: require('@/assets/about/thank/seven.png') },
        { src: require('@/assets/about/thank/eight.png') },
        { src: require('@/assets/about/thank/nine.png') },
        { src: require('@/assets/about/thank/ten.png') },
        { src: require('@/assets/about/thank/eleven.png') },
        { src: require('@/assets/about/thank/twelve.png') }

      ],
      timer: '',
      companyList: [
        { img: require('@/assets/about/bgs.png') },
        { img: require('@/assets/about/bgsTwo.png') },
        { img: require('@/assets/about/bgsThree.png') },
        { img: require('@/assets/about/bgsFour.png') }
      ],
      ygList: [
        { img: require('@/assets/about/ygOne.png') },
        { img: require('@/assets/about/ygTwo.png') },
        { img: require('@/assets/about/ygThree.png') },
        { img: require('@/assets/about/ygFour.png') }
      ],
      dialogShow: false
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/about')
      }
    },
    zzzsMove () {
      this.zzzsTime = setInterval(() => {
        if (!this.$refs.zzzsData) return
        if (
          this.$refs.zzzsData.offsetLeft <
          -this.$refs.zzzsData.offsetWidth / 2
        ) {
          this.$refs.zzzsData.style.left = 0
        } else {
          this.$refs.zzzsData.style.left =
          this.$refs.zzzsData.offsetLeft - 1 + 'px'
        }
      }, 15)
    },
    handZzzs (index) {
      clearInterval(this.zzzsTime)
      if (!index && index !== 0) {
        this.zzzsMove()
        return null
      }
    },
    thankMove () {
      this.timer = setInterval(() => {
        if (!this.$refs.thankData) return
        if (
          this.$refs.thankData.offsetLeft <
          -this.$refs.thankData.offsetWidth / 2
        ) {
          this.$refs.thankData.style.left = 0
        } else {
          this.$refs.thankData.style.left =
          this.$refs.thankData.offsetLeft - 1 + 'px'
        }
      }, 15)
    },
    handSchool (index) {
      clearInterval(this.timer)
      this.schoolShow = index

      if (!index) {
        this.thankMove()
        return null
      }
    }
  },
  mounted () {
    this.thankMove()
    this.zzzsMove()
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped lang='scss'>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
  padding: 0 1.25rem;
  box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/about/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutContainer{
  padding-top: 2.75rem;
}
.intraduce{
    margin: 1.875rem 0 1.25rem;
}
.intraduce p{
font-size: .9375rem;
color: #222222;
line-height: 1.5rem;

}
.intraLeft{
  position:relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  overflow: hidden;
}
.palyFengm{
  width:100%;
  transform: scale(1);
  transition: transform 0.2s;
  cursor: pointer;
}
.intraPlayBtn{
  width: 68px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-40%) scale(1);
  cursor: pointer;
}

.moWishContent{
  width: 100%;
  background: url('~@/assets/Mobile/about/wishContent.png') center no-repeat;
  background-size: cover;
}
.moWishContentDsec{
  width: 100%;
  background: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  box-sizing: border-box;
}
.qiywhItemImg{
  width: 40px;
}
.qiywhItemTitlt{
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 36px;
}
.qiywhItemText{
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
}

.moProcessContent{
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.processContainer{
  width: 100%;
  padding:0px 20px 60px;
  background: url('~@/assets/Mobile/about/processBg.png') center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}
.processStepContent{
  position: relative;
  top: 0;
  left: 0;
  padding-top: 68px;
}
.processStepItem{
  position: absolute;
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.processQunOne{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top:-5px;
  left: -5px;
  opacity: 0;
  border: 1px solid #D70C0C;
  transition: 0.5s;
}
.processQunTwo{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top:-10px;
  left: -10px;
  opacity: 0;
  border: 1px solid #D70C0C;
  transition: 0.5s;
}
.processStepText{
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  line-height: 24px;
}
.processStepTextActive{
  color: #fff;
}
.processStepActive{
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  color: #fff;
  .processQunOne{
    opacity: 0.8;
  }
  .processQunTwo{
    opacity: 0.4;
  }
}
.stepDescPosition{
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  color: #D70C0C;
  line-height: 19px;
  font-weight: 550;
  }
.processItem{
  width: 100%;
  margin-top: 60px;
  background: #FFFFFF;
  border-radius: 8px;
  opacity: 0.9;
  padding: 31px 40px;
}
.processItemTitle{
  font-size: 28px;
  font-weight: 500;
  color: #222222;
  line-height: 36px;
  margin-bottom: 10px;
}
.processText{
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
.moZzzsContainer{
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.zzzsListCon{
  width:100%;
  height: 340px;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}
.zzzsList{
  width: 3320px;
  height: 340px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.morongyzmContainer{
  width: 100%;
  box-sizing: border-box;
  background: #EFF1F3;
}
.ryzmListCOntent{
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-top: 60px;
  position: relative;
  top: 0;
  left: 0;
}
.ryzmList{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.ryzmItem{
  width: 180px;
  cursor: pointer;
}

.moJoinusContainer{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.moJoinCompany{
  width: 100%;
  padding: 0 20px 40px;
  box-sizing: border-box;
}
.joinUsTop{
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
}
.moJoinCompany{
  &::v-deep {
      .el-carousel__button {
      width: 8px;
      height: 8px;
      background: #d70c0c !important;
      border-radius: 5px;
    }
  }
}
.joinUsContent{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 0px 0px 10px 10px;
  padding: 20px 20px 30px;
  box-sizing: border-box;
}
.joinUsBtn{
  width: 200px;
  height: 48px;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15), 0px 8px 32px -6px rgba(131,7,7,0.4);
  border-radius: 34px;
  color: #fff;
  border: 0px;
  margin-top: 20px;
}

.moPersonContainer{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.personContent{
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
}

.fullPageVideo{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.2);
  z-index: 9999;
}
.closePlay{
  position: absolute;
  top: -30px;
  right:-10px;
  font-size: 25px;
  color: rgba(255, 255, 255,0.5);
  cursor: pointer;
}
.videoWrap{
  position: fixed;
  width: 90%;
  left: 50%;
  top: 40%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}

.jianliCOntent{

}
.jianliTop{
  position: relative;
  top: 0;
  left: 0;
}
.jianliTopText{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
}
.inputContent{
  padding: 20px 0 0;
}
.inputContentItem{
  margin-bottom: 20px;
}
.inputLable{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 22px;
  margin-bottom: 10px;
}
.inputRequire{
  color: #FF3A3A;
  font-size: 16px;
}
.inputContentItem{
  &::v-deep {
    .el-input__inner,.el-input ,.el-select{
      width: 100%;
    }

  }
}
.el-select-dropdown__item{
  padding-left:20px
}
.upLoadBtn{
  width: 114px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #0874E7;

  font-size: 14px;
  font-weight: 400;
  color: #0874E7;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.jianliBottom{
  display: flex;
  justify-content: center;
  align-items: center;
}
.jianliBottomBtn{
  text-align: center;
  width: 100px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 400;

  line-height: 40px;
}
</style>
